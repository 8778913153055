<template>
  <div class="ytm-landing-background">
    <div class="ellipse21 ytm-background-ellipse-size"/>
    <div class="ellipse22 ytm-background-ellipse-size"/>
    <div class="ellipse23 ytm-background-ellipse-size"/>
    <div class="ellipse24 ytm-background-ellipse-size"/>
    <div class="ellipse25 ytm-background-ellipse-size"/>
    <div class="ellipse26 ytm-background-ellipse-size"/>
    <div class="ellipse27 ytm-background-ellipse-size"/>
    <div class="ellipse28 ytm-background-ellipse-size"/>
    <div class="ellipse29 ytm-background-ellipse-size"/>
    <div class="ellipse30 ytm-background-ellipse-size"/>
  </div>
</template>

<script>
import './landing.css';

export default {
  name: 'LandingBackground',
};
</script>

<style>

</style>