<template>
  <div class="ytm-joinus-wrapper">
    <div style="flex-grow: 1; display: table">
      <div style="display: table-cell; vertical-align: middle">
        <p class="ytm-landing-font ytm-joinus-head" style="margin-bottom: max(0.5vw, 0.5vh)">Попробуйте!</p>
        <p class="ytm-landing-font ytm-joinus-call">Оцените возможности <b>YouTeachMe</b> прямо сейчас</p>
      </div>
    </div>
    <div style="display: table">
      <div style="display: table-cell; vertical-align: middle">
        <div class="ytm-joinus-buttons">
          <button class="ytm-joinus-button-join" href="#" @click="$router.push('/register')">
        <span class="ytm-landing-font ytm-joinus-button-text" style="margin-right: 1vw">
          Попробовать бесплатно!
        </span>
            <svg style="width: max(1.5vw, 0.8rem)" viewBox="0 0 34 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 13.3679C0.895431 13.3679 8.19842e-08 14.2634 0 15.3679C-8.19842e-08 16.4725 0.89543 17.3679 2 17.3679L2 13.3679ZM33.1296 16.7821C33.9106 16.0011 33.9106 14.7348 33.1296 13.9537L20.4016 1.22579C19.6206 0.444737 18.3543 0.444737 17.5732 1.22579C16.7922 2.00683 16.7922 3.27316 17.5732 4.05421L28.8869 15.3679L17.5732 26.6816C16.7922 27.4627 16.7922 28.729 17.5732 29.5101C18.3543 30.2911 19.6206 30.2911 20.4016 29.5101L33.1296 16.7821ZM2 17.3679L31.7153 17.3679L31.7153 13.3679L2 13.3679L2 17.3679Z" fill="white"/>
            </svg>
          </button>
          <button class="ytm-joinus-button-learn" @click="$router.push('/')">
        <span class="ytm-landing-font ytm-joinus-button-text" style="margin-right: 1vw">
          Узнать больше
        </span>
            <svg style="width: max(1.5vw, 0.8rem)" viewBox="0 0 34 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 13.3679C0.895431 13.3679 8.19842e-08 14.2634 0 15.3679C-8.19842e-08 16.4725 0.89543 17.3679 2 17.3679L2 13.3679ZM33.1296 16.7821C33.9106 16.0011 33.9106 14.7348 33.1296 13.9537L20.4016 1.22579C19.6206 0.444737 18.3543 0.444737 17.5732 1.22579C16.7922 2.00683 16.7922 3.27316 17.5732 4.05421L28.8869 15.3679L17.5732 26.6816C16.7922 27.4627 16.7922 28.729 17.5732 29.5101C18.3543 30.2911 19.6206 30.2911 20.4016 29.5101L33.1296 16.7821ZM2 17.3679L31.7153 17.3679L31.7153 13.3679L2 13.3679L2 17.3679Z" fill="white"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'JoinUs',
};
</script>

<style scoped>
.ytm-joinus-wrapper {
  background: #A23DF1;
  border-radius: 3vw;
  display: flex;
  padding: max(2vw, 2vh) max(3.5vw, 3.5vh);
}

.ytm-joinus-head {
  font-weight: 700;
  font-size: max(2vw, 1rem);
  color: #FFFFFF;
}

.ytm-joinus-call {
  font-weight: 500;
  font-size: max(1vw, 0.6rem);
  color: #FFFFFF;
}

.ytm-joinus-button-join {
  margin-left: 1vw;
  margin-bottom: 1vh;
  background: #07A8F0;
  border: 2px solid #07A8F0;
  border-radius: 15px;
  display: flex;
  padding: 0.6vw 1.2vw;
  cursor: pointer;
}

.ytm-joinus-button-learn {
  margin-left: 1vw;
  background: transparent;
  border: 2px solid #FFFFFF;
  border-radius: 15px;
  display: flex;
  padding: 0.6vw 1.2vw;
  cursor: pointer;
}

.ytm-joinus-button-text {
  font-weight: 600;
  font-size: max(1.1vw, 0.6rem);
  color: #FFFFFF;
  white-space: nowrap;
}

.ytm-joinus-buttons {
}

@media only screen and (min-width: 650px) {
  .ytm-joinus-buttons {
    display: flex;
  }

  .ytm-joinus-button-join {
    margin-bottom: 0;
  }
}
</style>