<template>
  <div style="display: flex; margin-top: 6.25vw; margin-bottom: 1vw">
    <div class="ytm-footer-flex-col" style="display: table">
      <div style="display: table-cell; vertical-align: middle">
        <p class="ytm-landing-font ytm-footer-text">© 2022-{{yearNow()}} ИП Волобуев Николай Анатольевич</p>
        <p class="ytm-landing-font ytm-footer-text">ИНН: 022403008697</p>
        <p class="ytm-landing-font ytm-footer-text">ОГРН: 324784700048521</p>
        <p class="ytm-landing-font ytm-footer-text"><a
                class="ytm-landing-font ytm-footer-text"
                href="https://disk.yandex.ru/i/1gT_UYF_V7gCUg"
                style="color: #000000"
                target="_blank"
                rel="noopener noreferrer"
        >
            Пользовательское соглашение
        </a></p>
        <p class="ytm-landing-font ytm-footer-text"><a
                class="ytm-landing-font ytm-footer-text"
                href="https://disk.yandex.ru/i/PqtVro8TS5FK2Q"
                style="color: #000000"
                target="_blank"
                rel="noopener noreferrer"
        >
            Политика конфиденциальности
        </a></p>
      </div>
    </div>
    <div class="ytm-footer-flex-col" style="display: table">
      <div style="display: table-cell; vertical-align: middle">
        <p class="ytm-landing-font ytm-footer-text"><a
            class="ytm-landing-font ytm-footer-text"
            href="https://t.me/volobushek"
            style="color: #000000"
            target="_blank"
            rel="noopener noreferrer"
        >
          Помощь
        </a></p>
        <p class="ytm-landing-font ytm-footer-text"><a
            class="ytm-landing-font ytm-footer-text"
            href="https://t.me/volobushek"
            style="color: #000000"
            target="_blank"
            rel="noopener noreferrer"
        >
          Обратная связь
        </a></p>
          <p class="ytm-landing-font ytm-footer-text"><a
                  class="ytm-landing-font ytm-footer-text"
                  href="https://t.me/youteachme"
                  style="color: #000000"
                  target="_blank"
                  rel="noopener noreferrer"
          >
              Telegram-канал
          </a></p>
        <p class="ytm-landing-font ytm-footer-text"><a
                class="ytm-landing-font ytm-footer-text"
                href="mailto:support@youteachme.ru"
                style="color: #000000"
                target="_blank"
                rel="noopener noreferrer"
        >
            support@youteachme.ru
        </a></p>
      </div>
    </div>
    <div>
      <div class="ytm-footer-business-computer"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  methods: {
    yearNow() {
      const now = new Date();
      return now.getFullYear();
    },
  },
}
</script>

<style scoped>
.ytm-footer-flex-col {
  flex-grow: 1;
}

.ytm-footer-text {
  margin-bottom: 1vw;
  margin-top: 0;
  font-weight: 400;
  font-size: max(1vw, 0.6rem);
  color: #000000;
}

.ytm-footer-business-computer {
  width: 14vw;
  height: 12vw;
  background-image: url("../../../public/business_computer.webp");
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -50;
}
</style>